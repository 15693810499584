.withdrawalContainer {
    background: #ebebeb;
    font-family: var(--font-primary);
}

.headingParent {
    display: flex;
    justify-content: center;
}

.heading {
    font-size: clamp(16px, 3.12vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    max-width: 65vw;
    text-align: center;
    color: #35255a;
}

.imagesRow {
    padding: clamp(20px, 2.6vw, 50px) clamp(15px, 2.6vw, 50px);
}

.imagePartnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.imageParent {
    width: clamp(56px, 10.5vw, 202px);
    height: clamp(58px, 10.8vw, 209px);
    padding: clamp(10px, 1vw, 20px);
    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/withdrawalPartner_container.webp") no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: clamp(10px, 1.3vw, 25px);
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 767px) {
    .heading {
        letter-spacing: 0.64px;
        color: #35255a;
    }
}